
let lat = gon.cklat
let lng = gon.cklng
let zoom = gon.ckzoom
let map

$(function() {
  map = new geolonia.Map({
    container: 'geolonia-map',
  })

  map.on('load', function () {
    resetPosition()
  })

  map.addControl(
    new geolonia.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserLocation: true,
      showAccuracyCircle: false,
    })
  )

  let search_box = document.getElementById('search_box')
  if(search_box) addEventListener('keypress', searchAddress)

  $("#btn_resetPosition").on("click", resetPosition)
  $("#btn_list_view").on("click", list_view)

})

function resetPosition() {
  getPosition({enableHighAccuracy: true, timeout: 5000})
  .then((position) => {
    lat = position.coords.latitude
    lng = position.coords.longitude
    initMapCenter(lat, lng, zoom)
    list_view()
  })
  .catch((err) => {
    alert("位置情報を取得できません。位置情報アクセスを有効にしてください。")
  })
}

function getPosition(options) {
  return new Promise((resolve, reject) => 
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  )
}

function initMapCenter(lat, lng, zoom) {
  map.jumpTo({center: [lng, lat], zoom: zoom})
  map.on('moveend', function() {
  })
}

function searchAddress(e) {
  if (e.keyCode === 13 && search_box.value) {
    getLatLng(search_box.value, (place) => {
      if (place.level == 1) {
        $.getJSON('https://geolonia.github.io/japanese-prefectural-capitals/', function(data) {
          for (const pref in data) {
            if (pref === place.pref) {
              map.flyTo({
                center: [data[pref].lng, data[pref].lat],
                essential: true
                })
            }
          }
        })
      } else if (place.level == 2) {
        $.getJSON('https://geolonia.github.io/japanese-addresses/api/ja/'+place.pref+'/'+place.city+'.json', function(data) {
          for (let i = 1; i < data.length; i++){
            if (data[i].lng && data[i].lat) {
              map.flyTo({
                center: [data[i].lng, data[i].lat],
                essential: true
                })
              break
            }
          }
        })
      } else if (place.level == 3) {
        map.flyTo({
          center: [place.lng, place.lat],
          essential: true
          })
      } else {
        alert('「' + search_box.value + '」が見つかりませんでした。住所を修正してもう一度お試しください。')
      }
    }, (error) => {
      alert('「' + search_box.value + '」が見つかりませんでした。住所を修正してもう一度お試しください。')
    })
  }
}

function list_view() {
  redrawList = 0
  let center_lng = map.getCenter().lng
  let center_lat = map.getCenter().lat
  if ($("#nearlist").length > 0) { $("#nearlist").remove() }
  let url = "/spots/nearest?lat=" + center_lat + "&lng=" + center_lng + "&distance_limit=10000"
  $.getJSON(url, function(data){
    if (data.length > 0) {
      if ($("#no_list_image").length > 0) { $("#no_list_image").remove() }
      $("#list_view").append("<div id='nearlist" + "'></div>")
      $("#nearlist").append("<div id='nearlist_title" + "'></div>")
      $("#nearlist_title").html("<h4>"+label_spots_near+"</h4>")
      for (i = 0; i < data.length; i++) {
        $("#nearlist").append("<div id='nearlist_" + i + "' class='well thumb'></div>")
        $("#nearlist_" + i).append("<a href='/spots/" + data[i]['id'] + "'><img src='" + data[i]['square_thumb_url'] + " id='nearlist_img_" + i + "' class='photo'/></a>")
        if (data[i]['user_thumbnail'] === '/avatars/original/missing.png') {
          $("#nearlist_" + i).append("<p><a href='/spots/" + data[i]['id'] + "'><i class='fas fa-user'></i></a></b> <b><a href='/user/" + data[i]['spot_username'] + "'>" + data[i]['spot_username'] + "</a></b> (" + data[i]['format_created_at'] + ")</p>" )
        } else {
          $("#nearlist_" + i).append("<p><a href='/spots/" + data[i]['id'] + "'><img alt='" + data[i]['user_thumbnail'] + "' class='usericon_s circle' src='" + data[i]['user_thumbnail'] + "'/></a> <b><a href='/user/" + data[i]['spot_username'] + "'>" + data[i]['spot_username'] + "</a></b> (" + data[i]['format_created_at'] +")</p>" )
        }

        $("#nearlist_" + i).append(label_distance_from_this_spot + "<b>" + data[i]['format_distance'] + '</b><br>' )
        $.each(data[i]['tag_array'], function(g, value) {
          $("#nearlist_" + i).append('<a class="badge bg-success text-white" href="/spots/tag/' + value + '">' + value + '</a> ' )
        })
        $label_class = (data[i]['text_language'].indexOf("ja") >= 0) ? "bg-dark text-white" : "bg-notext" 
        $("#nearlist_" + i).append('<a class="badge ' + $label_class + '" href="/spots/' + data[i]['id'] + "\">"+label_language_tag_ja+"</a> " )
        $label_class = (data[i]['text_language'].indexOf("en") >= 0) ? "bg-dark text-white" : "bg-notext"
        $("#nearlist_" + i).append('<a class="badge ' + $label_class + '" href="/spots/' + data[i]['id'] + "\">"+label_language_tag_en+"</a> " )
        $label_class = (data[i]['text_language'].indexOf("cn") >= 0) ? "bg-dark text-white" : "bg-notext"
        $("#nearlist_" + i).append('<a class="badge ' + $label_class + '" href="/spots/' + data[i]['id'] + "\">"+label_language_tag_cn+"</a> " )
      }
    }
  })
}
